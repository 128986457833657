import { req } from '@/api';
import {
    defineComponent,
    nextTick,
    onMounted,
    onUpdated,
    ref,
    Ref,
} from '@vue/composition-api';

import FetchData from '@/components/FetchData';
import store from '@/store';

interface FriendsData {
    onlyid: string;
    title: string;
    avatar: string;
    description: string;
    url: string;
    clickCount: number;
    mainColor?: string;
}
interface ColorAndBGColorStyle {
    color?: string;
    backgroundColor: string;
    backgroundImage?: string;
}

export default defineComponent({
    components: { FetchData },
    setup() {
        const friendsData: Ref<FriendsData[]> = ref([
            {
                onlyid: '',
                title: '标题占位',
                avatar: store.state.limitImgUrl || '',
                description: '描述占位描述占位描述占位描述占位描述占位描述占位',
                url: '',
                clickCount: -1,
                mainColor: '',
            },
        ]);
        const touchData = (data: any) => {
            //console.log('接收了', data);
            //console.log(data);
            friendsData.value = data;
            //
            friendsData.value.forEach(item => {
                getBackgroundMainColor(item.onlyid, item.avatar);
            });
        };

        const openLink = (onlyid: string) => {
            const openWindow = window.open(
                `/redirect/${onlyid}`,
                `foo-${onlyid}`,
                'noopener=yes,noreferrer=yes'
            );
            if (openWindow !== null) {
                //(openWindow as any).alert('123');
            }
        };
        //功能块，实现图片的主题颜色
        const themeColor: Ref<any> = ref({
            //键onlyid，值颜色
        });
        const getBackgroundMainColor = (
            onlyid: string,
            avatar: string
        ): boolean | ColorAndBGColorStyle => {
            //themeColor.value[onlyid] = 'rgba(127,127,127,.8)';
            if (typeof themeColor.value[onlyid] === 'string') {
                return false;
            }
            const oImg = new Image();
            oImg.onload = function () {
                //console.log(avatar + 'is loaded');
                try {
                    const oCanvas = document.createElement('canvas');
                    oCanvas.width = oImg.width;
                    oCanvas.height = oImg.height;
                    const context = oCanvas.getContext('2d');
                    oImg.crossOrigin = 'Anonymous';
                    context?.drawImage(oImg, 0, 0, oImg.width, oImg.height);
                    //console.log(1);
                    if (oImg.width <= 0 || oImg.height <= 0) {
                        return false;
                    }
                    //获取像素数据
                    const data = context?.getImageData(
                        0,
                        0,
                        oImg.width,
                        oImg.height
                    ).data;
                    let r = 1,
                        g = 1,
                        b = 1;

                    //console.log(2);
                    for (let row = 0; row < oImg.height; row++) {
                        for (let col = 0; col < oImg.width; col++) {
                            if (
                                typeof data === 'object' ||
                                Array.isArray(data)
                            ) {
                                //console.log('执行中');
                                if (row === 0) {
                                    r += data[oImg.width * row + col] || 0;
                                    g += data[oImg.width * row + col + 1] || 0;
                                    b += data[oImg.width * row + col + 2] || 0;
                                } else {
                                    r +=
                                        data[(oImg.width * row + col) * 4] || 0;
                                    g +=
                                        data[
                                            (oImg.width * row + col) * 4 + 1
                                        ] || 0;
                                    b +=
                                        data[
                                            (oImg.width * row + col) * 4 + 2
                                        ] || 0;
                                }
                            }
                        }
                    }
                    const square = oImg.width * oImg.height;
                    r = Math.round(r / square);
                    g = Math.round(g / square);
                    b = Math.round(b / square);
                    //console.log('rgb', r, g, b);
                    //themeColor.value = Object.assign(themeColor.value, {
                    //    [onlyid]: `rgba(${r},${g},${b},0.1)`,
                    //});
                    //[onlyid] = `rgba(${r},${g},${b},0.1)`;
                    //console.log(themeColor.value);
                    const findIndex = friendsData.value.findIndex(
                        item => item.onlyid === onlyid
                    );
                    if (findIndex >= 0) {
                        friendsData.value[
                            findIndex
                        ].mainColor = `rgba(${r},${g},${b},0.15)`;
                        friendsData.value = [...friendsData.value];
                    }
                } catch (e) {
                    console.warn(e);
                }
            };
            oImg.src = avatar;
            return false;
        };
        const getColorByOnlyid = (onlyid: string) => {
            return themeColor.value[onlyid] || 'rgba(127,127,127,0.1)';
        };
        onUpdated(() => {
            //
        });
        return {
            friendsData,
            openLink,
            touchData,
            themeColor,
            getBackgroundMainColor,
            getColorByOnlyid,
        };
    },
});
