import { defineComponent, Ref, ref } from '@vue/composition-api';

interface LinkList {
	key: string;
	title: string;
	t?: string;
	decsription: string;
	path: string;
}

export default defineComponent({
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const linkList: Ref<LinkList[]> = ref([
			{
				key: 'friends',
				t: 'friends',
				title: '我的好友',
				decsription: '',
				path: '/links/friends',
			},
			{
				key: 'group',
				t: 'group',
				title: '圈子',
				decsription: '',
				path: '/links/group',
			},
			{
				key: 'favorite',
				t: 'favorites',
				title: '收藏夹',
				decsription: '',
				path: '/links/favorites',
			},
			{
				key: 'register',
				t: 'join',
				title: '申请友链',
				decsription: '',
				path: '/links/register',
			},
		]);
		return { linkList, t };
	},
});
