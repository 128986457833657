import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import addWatch from './add-watch';

let timer: number | undefined = undefined;

export default defineComponent({
	props: {
		src: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			required: false,
		},
		height: {
			type: String,
			required: false,
		},
		isPauseMusic: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup(props: any, { root }: any) {
		let _isMounted = false;
		const { src, width, height, isPauseMusic } = props;
		const flashStyleWidthAndHeight = width && height ? { width, height } : {};
		const { $store, $message } = root;
		const t = root.$t.bind(root);
		const isNoActive: Ref<boolean> = ref(true);
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const loadingText: Ref<string> = ref('初始化Flash插件');
		const errorText: Ref<string> = ref('未知错误');
		const flashDOM: Ref<any> = ref(null);
		let hasControlMusicPlayer = false;
		watch(
			() => isNoActive.value,
			value => {
				if (!value) {
					//将vuex里面的flash激活状态改为true
					if ($store) {
						$store?.commit('activeFlash');
					}
					//flash的加载状态为true
					isLoading.value = true;
					loadingText.value = '正在加载Flash模块';
					//判断能否加载flash
					const shouldContinue: boolean = (() => {
						let res = false;
						if (typeof window.WebAssembly === 'object') {
							if (typeof window.WebAssembly.Memory === 'function') {
								if (typeof window.WebAssembly.instantiateStreaming === 'function' || typeof window.WebAssembly.instantiate === 'function') {
									res = true;
								}
							}
						}
						return res;
					})();
					//开始加载flash
					new Promise((resolve, reject) => {
						if (!shouldContinue) {
							reject(
								'浏览器不支持WebAssembly，程序无法运行' //建议您升级至Edge 89以上版本
							);
						}
						try {
							const fn = new Function('()=>{}');
							fn();
							window.eval('()=>{}');
						} catch (e) {
							reject('无法加载Ruffle Flash Player，请升级您的浏览器');
						}
						if ((window as any).RufflePlayer && typeof (window as any).RufflePlayer.newest === 'function') {
							resolve((window as any).RufflePlayer);
						} else {
							document.head.append(
								(() => {
									const oFlashScript = document.createElement('script');
									oFlashScript.setAttribute('src', '/static/js/package/ruffle/ruffle.js');
									oFlashScript.setAttribute('async', '');
									oFlashScript.setAttribute('data-ruffle-flash-player', '');
									oFlashScript.addEventListener('error', () => {
										reject('无法加载Flash插件，请前端程序员检查vue组件与wasm组件的对接');
									});
									oFlashScript.addEventListener('load', () => {
										if ((window as any).RufflePlayer && typeof (window as any).RufflePlayer.newest === 'function') {
											(window as any).RufflePlayer.config = {
												// Options affecting the whole page
												publicPath: undefined,
												polyfills: true,
												// Options affecting files only
												autoplay: 'auto',
												unmuteOverlay: 'visible',
												backgroundColor: null,
												letterbox: 'fullscreen',
												warnOnUnsupportedContent: true,
												contextMenu: true,
												//"showSwfDownload": false,
												upgradeToHttps: window.location.protocol === 'https:',
												//"maxExecutionDuration": {"secs": 15, "nanos": 0},
												logLevel: 'error',
												//base: null,
												//menu: true,
												//salign: '',
												//scale: 'showAll',
												//quality: 'high',
											};

											console.log(
												'%cRuffle Flash Player %chttps://ruffle.rs',
												'border-top-left-radius:5px;border-bottom-left-radius:5px;margin-top:7px;margin-bottom:7px;padding:7px;background-color:#37528C;color:#FFAD33',
												'border-top-right-radius:5px;border-bottom-right-radius:5px;margin-top:7px;margin-bottom:7px;padding:7px;background-color:#FFAD33;'
											);
											setTimeout(() => {
												resolve((window as any).RufflePlayer);
											}, 1);
										} else {
											reject('Flash播放器核心组件损坏');
										}
									});
									clearTimeout(timer);
									timer = setTimeout(() => {
										reject('网络状况不好，Flash加载超时');
									}, 10000);
									return oFlashScript;
								})()
							);
						}
						//resolve('ok');
						//reject('flash');
					})
						.then((player: any) => {
							let flashPlayer: any;
							//console.log('获取到了对象', player);
							if (_isMounted) {
								isLoading.value = true;
								loadingText.value = '正在解析Flash文件';
								flashPlayer = player.newest().createPlayer();
								if (width && height) {
									flashPlayer.style.width = width;
									flashPlayer.style.height = height;
								}
								if (!flashPlayer) {
									$message?.error('无法在页面渲染Flash动画');
									return;
								}
								//console.log(flashDOM.value);
								flashDOM.value.appendChild(flashPlayer);
								flashPlayer
									.load('' + src)
									.then(() => {
										isLoading.value = false;
										//如果定义了暂停音乐播放器，则暂停
										if (isPauseMusic && typeof $store !== 'undefined') {
											$store?.commit('otherComponentControlMusicPlayerPause', true);
											hasControlMusicPlayer = true;
										}
										//添加监听，以便其他组件控制flash的播放暂停等
										addWatch(flashPlayer);
									})
									.catch(() => {
										//
									});
							}
						})
						.catch((error: string) => {
							isError.value = true;
							isLoading.value = false;
							errorText.value = error;
							$message ? $message?.error(error) : alert(error);
						});
				}
			}
		);
		onMounted(() => {
			_isMounted = true;
			//组件渲染后，检查flash是否全局激活
			if ($store?.state?.isFlashPlayerActive) {
				isNoActive.value = false;
			}
		});
		onBeforeUnmount(() => {
			_isMounted = false;
			clearTimeout(timer);
			//如果控制了播放器，则恢复播放器
			if (hasControlMusicPlayer) {
				$store?.commit('otherComponentControlMusicPlayerPause', false);
			}
		});
		return {
			isNoActive,
			isLoading,
			isError,
			loadingText,
			errorText,
			flashDOM,
			flashStyleWidthAndHeight,
			t,
		};
	},
});
