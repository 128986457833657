import { defineComponent, onBeforeUnmount, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub/index.vue';
import Flash from '@/components/Flash';

import Friends from '@/components/Article/Friends/index.vue';
import FriendsHot from '@/components/Article/FriendsHot';
import LinkNav from '@/components/Article/LinkNav';
import LoveUU from '@/components/Article/LoveUU';

import axios from 'axios';

export default defineComponent({
	components: {
		Card,
		PageNodeMain,
		PageNodeSub,
		Flash,
		Friends,
		FriendsHot,
		LoveUU,
		LinkNav,
	},
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const isShowFlash: Ref<boolean> = ref(true);
		const resizeEvent = (event: Event | undefined = undefined) => {
			const offsetWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
			if (offsetWidth < 1367) {
				isShowFlash.value = false;
			} else {
				isShowFlash.value = true;
			}
		};
		onMounted(() => {
			window.addEventListener('resize', resizeEvent);
			resizeEvent();
		});
		onBeforeUnmount(() => {
			window.removeEventListener('resize', resizeEvent);
		});
		const isShowFlashMask: Ref<boolean> = ref(true);
		const clickUU = () => {
			isShowFlashMask.value = false;
			axios.get('/redirect/usuuu.com').catch(() => {
				/** */
			});
			//alert('click');
		};
		return { isShowFlash, clickUU, isShowFlashMask, t };
	},
});
