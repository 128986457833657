import { defineComponent, watch, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import store from '@/store';
export default defineComponent({
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const getLoveDate = () => {
			const fromDate = new Date('2020/10/24 00:00:00');
			const nowDate = new Date();
			//console.log(nowDate.getTime(), fromDate.getTime());
			return Math.floor((nowDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24) + 1);
		};
		const isSvgShow: Ref<boolean> = ref(true);
		watch(
			() => store.state.darkMode,
			newValue =>
				(isSvgShow.value =
					!/Trident/.test(navigator.userAgent) && !newValue
						? !!(typeof window.CSS === 'object' && typeof window.CSS.supports === 'function' && window.CSS.supports('mix-blend-mode', 'screen'))
						: false),
			{ immediate: true }
		);
		const isLightMode: Ref<boolean> = ref(true);
		watch(
			() => store.state.darkMode,
			value => (isLightMode.value = !value)
		);
		return { getLoveDate, t, isSvgShow, isLightMode };
	},
});
