import { watch } from '@vue/composition-api';
import store from '@/store';

const addWatch = (flashPlayer: any) => {
    watch(
        () => store.state.flashCmd,
        value => {
            if (!store.state.isFlashPlayerActive) {
                alert('Flash播放器尚未激活');
            }
            const cmd = value.split('@')[0];
            switch (cmd) {
                case 'play':
                    try {
                        flashPlayer.play();
                    } catch (e) {
                        //
                    }

                    break;
                case 'pause':
                    flashPlayer.pause();
                    break;
                case 'fullscreen':
                    flashPlayer.setAttribute('allowfullscreen', 'true');
                    try {
                        flashPlayer.requestFullscreen()?.catch(() => {
                            /** */
                        });
                    } catch (e) {
                        try {
                            flashPlayer.mozRequestFullScreen()?.catch(() => {
                                /** */
                            });
                        } catch (e) {
                            try {
                                flashPlayer.msRequestFullscreen()?.catch(() => {
                                    /** */
                                });
                            } catch (e) {
                                try {
                                    flashPlayer
                                        .webkitRequestFullScreen()
                                        ?.catch(() => {
                                            /** */
                                        });
                                } catch (e) {
                                    alert('浏览器不支持');
                                }
                            }
                        }
                    }
                    break;
                default:
                    alert('功能暂不可用');
            }
        },
        { immediate: false }
    );
};

export default addWatch;
