import { defineComponent, ref, Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';
import store from '@/store';

export default defineComponent({
	components: { FetchData },
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const hotData: Ref<any[]> = ref([
			{
				onlyid: 'a',
				title: '占位符',
				url: 'url',
				recentClick: 999,
			},
		]);
		const touchData = (data: any) => {
			hotData.value = data;
		};
		const openLink = (onlyid: string) => {
			const openWindow = window.open(`/redirect/${onlyid}`, `foo-${onlyid}`, 'noopener=yes,noreferrer=yes');
			if (openWindow !== null) {
				//(openWindow as any).alert('123');
			}
		};
		const randLightColor = () => {
			function getNum(): number {
				let rand = Math.floor(Math.random() * 63);
				if (!store.state.darkMode) {
					rand = 255 - rand;
				}
				return rand;
			}
			return `rgb(${getNum()},${getNum()},${getNum()})`;
		};
		return { hotData, touchData, openLink, randLightColor, t };
	},
});
